import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const partners = [
  {
    id: 1,
    name: "Binance",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Bincance.png",
    link: "https://www.binance.com/es",
  },
//   {
//     id: 2,
//     name: "Credicorp Bank",
//     logoSrc:
//       "https://img.invupos.com/webinvu/Our%20Partners%20/Credicorp%20Bank.svg",
//     link: "https://www.credicorpbank.com",
//   },
//   {
//     id: 3,
//     name: "Banesco",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Banesco.svg",
//     link: "https://www.banesco.com.pa/",
//   },
  {
    id: 2,
    name: "Shopify",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Shopify.svg",
    link: "https://es.shopify.com/",
  },
  {
    id: 3,
    name: "Woo Commerce",
    logoSrc:
      "https://img.invupos.com/webinvu/Our%20Partners%20/Woo%20Comerce.svg",
    link: "https://woocommerce.com/",
  },
  {
    id: 4,
    name: "Quickbooks",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/QuickBooks.svg",
    link: "https://quickbooks.intuit.com/",
  },
  {
    id: 5,
    name: "Magento 2",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Magento.svg",
    link: "https://marketplace.magento.com/",
  },
//   {
//     id: 8,
//     name: "BAC Credomatic",
//     logoSrc:
//       "https://img.invupos.com/webinvu/Our%20Partners%20/Bac%20credomatic.svg",
//     link: "https://www.baccredomatic.com/",
//   },
  {
    id: 6,
    name: "SAP Business ONE",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/SAP.svg",
    link: "https://store.sap.com/dcp/en/",
  },
//   {
//     id: 10,
//     name: "Banco General",
//     logoSrc:
//       "https://img.invupos.com/webinvu/Our%20Partners%20/Banco%20General.svg",
//     link: "https://www.bgeneral.com/",
//   },
//   {
//     id: 10,
//     name: "Sage 50",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Sage%2050.svg",
//     link: "https://www.sage.com/",
//   },
//   {
//     id: 11,
//     name: "APC Buró",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Apc%20buru.svg",
//     link: "https://www.intelidat.com/",
//   },
  {
    id: 7,
    name: "Clau.io",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Clau.io.svg",
    link: "https://clau.io/",
  },
  {
    id: 8,
    name: "Xero",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Xero.svg",
    link: "https://www.xero.com/",
  },
  {
    id: 9,
    name: "Market Man",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/MarketMan.svg",
    link: "https://www.marketman.com/",
  },
  {
    id: 10,
    name: "Rappi",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Rappi.svg",
    link: "https://www.rappi.com.mx/",
  },
  {
    id: 11,
    name: "Open Table",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Opentablev1.svg",
    link: "https://www.opentable.com/",
  },
//   {
//     id: 17,
//     name: "Yappy",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Yappy%20v4.svg",
//     link: "https://yappy.com.pa/",
//   },
  {
    id: 12,
    name: "Deliverect",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Deliverect.svg",
    link: "https://www.deliverect.com/es",
  },
//   {
//     id: 19,
//     name: "Hero",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Hero.svg",
//     link: "https://try.herofacturas.com/",
//   },
  {
    id: 13,
    name: "Mews",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Mews.svg",
    link: "https://www.mews.com/es/",
  },
//   {
//     id: 21,
//     name: "Sage Business Cloud",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Sage%20Business%20Cloud%282%29.svg",
//     link: "https://www.sage.com/es-es/productos/sage-50cloud/",
//   },
  {
    id: 14,
    name: "Cloudbeds",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Cloudbeds.svg",
    link: "https://www.cloudbeds.com/es/",
  },
//   {
//     id: 23,
//     name: "Globalbank",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Globalbank.svg",
//     link: "https://www.globalbank.com.pa/",
//   },
//   {
//     id: 24,
//     name: "Zoho",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Zoho.svg",
//     link: "https://www.zoho.com/es-xl/",
//   },
//   {
//     id: 25,
//     name: "Sage",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Sage.svg",
//     link: "https://www.sage.com/es-es/",
//   },
//   {
//     id: 25,
//     name: "Mr Pedidos",
//     logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/new/Mr%20Pedidos%281%29.svg",
//     link: "https://reparaciondemadera.online/",
//   },
  {
    id: 15,
    name: "Oracle NETSUITE",
    logoSrc: "https://img.invupos.com/webinvu/Our%20Partners%20/Oracle%20Netsuite.svg",
    link: "https://www.netsuite.com/portal/home.shtml?noredirect=T",
  },
];

const Partners_mx = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  return (
    <section className="bg-gray-100 py-10 mt-24">
      <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div class="flex flex-col w-full mb-8 text-center">
          {/* <p class="mb-4 text-sm font-medium tracking-wide text-gray-500 uppercase">
            {" "}
            {t("partnerstittle")}{" "}
          </p> */}
           <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl ">
            <div>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("partnerstittle1")}
              </h2>
              <p className="text-base text-gray-700 md:text-base">
              {t("partnersdescription")}
              </p>
            </div>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-6 text-center lg:grid-cols-5">
          {partners.map((partner) => (
            <a key={partner.id} href={partner.link} target="_blank">
              <div class="flex items-center justify-center p-6 bg-white rounded-xl border">
                <img
                  src={partner.logoSrc}
                  alt={partner.name}
                  loading="lazy"
                  class="block object-contain h-10"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners_mx;
